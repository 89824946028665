<template>
  <div>
    <h1>使用者權限</h1>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
